body {
    background-color: #ffffff;
    margin-right: 10px;
    margin-left: 10px;
    font-family: 'Nunito', sans-serif;

}

.app_form {
    justify-content: center;
    align-items: center;
    max-width: 400px;
    margin: 0 auto; /* Центрирование контейнера */
}

.app_form h1 {
    margin-bottom: 0;
}

.input_name {
    font-family: 'Nunito', sans-serif;
    width: 90%;
    background-color: white;
    color: black;
    border: 1px solid #e1e6f0;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 16px;
    line-height: 1.2;
    /*cursor: pointer;*/
    transition: background-color 0.3s ease, border-color 0.3s ease;
    margin-bottom: 7%;
}


.input_name:focus {
    outline: none;
    border-color: #2863e1;
}

.personal_form {
    margin-top: 5%;
    margin-left: 2%;
    margin-right: 2%;

}

.phone-input {
    color: black;
    font-size: 16px;
    line-height: 1.2;
    background-color: white;
    display: flex;
    border-radius: 5px;
    /*padding-bottom: 10px;*/
    /*padding-top: 10px;*/
}

.country-select {
    /*border-radius: 0;*/
    border-radius: 5px 0 0 5px;
    border: 1px solid #e1e6f0;
    cursor: pointer;
    background-color: #ffffff;
    width: 25%;
    padding-left: 5px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 14px;
    font-weight: lighter;
}

.country-code {
    font-size: 6px;
}

.phone-number-input {
    font-family: 'Nunito', sans-serif;
    padding-left: 10px;
    width: 70%;
    border: 1px solid #e1e6f0;
    border-radius: 0 5px 5px 0;
    font-size: 14px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.country-select:focus, .phone-number-input:focus {
    outline: none;
    border: 1px solid #2863e1;
}


.input_budget {
    font-family: 'Nunito', sans-serif;
    margin-top: 7%;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000;
}

.input_budget_span {
    margin-bottom: 3%;
    display: block;
}

input[type="radio"] {
    cursor: pointer;
    align-content: center;
    align-items: center;
    justify-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 30px;
    height: 30px;
    border: 1px solid #e1e6f0;
    border-radius: 50%;
    outline: none;
    transition: background 0.3s ease;
    box-sizing: border-box;

}

input[type="radio"]:checked {
    outline: none;
    border-color: #2863e1;
}

input[type="radio"]:checked::before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    margin: 5px;
    border-radius: 50%;
    background: #2863e1;
}

.radio-container {
    /*display: flex;*/
    display: block;
    align-items: center;
    /*text-align: center;*/
    margin-bottom: 4%;
    /*gap: 10px;*/
}

.radio-container input {
    vertical-align: middle; /* Центрирование радио-кнопки */
}

.radio-container span {
    display: inline-block;
    font-family: 'Nunito', sans-serif;
    vertical-align: middle; /* Центрирование текста рядом с радио-кнопкой */
    margin-left: 8px; /* Отступ слева от радио-кнопки */
    margin-top: 1%;
}

.add_form {
    margin-top: 5%;
    font-size: 14px;
    margin-left: 2%;
    margin-right: 2%;
}

.field_add_form, .field_add_form_select {
    font-family: 'Nunito', sans-serif;
    display: block;
    padding: 10px 15px;
    width: 99%;
    background-color: white;
    color: black;
    border: 1px solid #e1e6f0;
    border-radius: 5px;
}

.field_add_form_select{
    cursor: pointer;
    font-family: 'Nunito', sans-serif;
    border-radius: 5px;
    border: 1px solid #e1e6f0;
}


.field_add_form {
    width: 90%;
}

.field_add_form:focus, .field_add_form_select:focus {
    outline: none;
    border-color: #2863e1;
}

.add_form label {
    display: flex;
    flex-direction: column; /* Размещает текст и элементы вертикально */
    margin-bottom: 5%; /* Отступ между лейблом и следующими элементами */

}

.add_form select, .add_form input {
    margin-top: 1%;
}

.form_button {
    color: white;
    background-color: #2863e1;
    outline: none;
}

.main-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 1;
    padding: 12px 25px;
    color: #fff;
    background-color: #1a53c6;
    margin: 0 auto;
    border-radius: 6px;
    border: 1px solid #e1e6f0;
    cursor: pointer;
    outline: none;
}

.main-btn:hover {
    outline: none;
    background-color: #2863e1
}

.error {
    font-family: 'Nunito', sans-serif;
    color: red;
    font-size: 14px;
    font-weight: 300;
}

.out{
    margin-top: 300px;
}